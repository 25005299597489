import { access } from '@driftt/traffic-control'

export const {
  hasScopes,
  hasAnyScope,
  hasGates,
  hasAnyRole,
  isAdmin,
  isAgent,
  isUser,
  hasFeatures,
  hasDefault,
  hasAnyDefaultRole,
} = access

export const isReportingUser = () => hasAnyRole('Reporting')
export const isPlaybooksUser = () => hasAnyRole('Playbooks')
export const isMarketerUser = () => hasAnyDefaultRole('Marketer')
export const isSalesRepUser = () => hasAnyDefaultRole('Sales Rep')
export const isSalesManagerUser = () => hasAnyDefaultRole('Sales Manager')
export const isServiceUser = () => hasAnyDefaultRole('Service Agent')

export const userHasFullDashboardAccess = () =>
  isAdmin() ||
  isReportingUser() ||
  hasFeatures({ featureName: 'dashboard', accessLevel: 'READ' })
export const hasGoalsPreview = () =>
  isAdmin() && hasGates('has_onboarding_experience')

export const userHasAllReportAccess = () => isAdmin() || hasAnyRole('Reporting')

export const isNonAdminChatUser = () => hasAnyScope('member') && !isAdmin()

const userHasMyConversationReportsFeature = () =>
  hasFeatures({ featureName: 'my_conversations_reports', accessLevel: 'READ' })

const userHasOrgConversationReportsFeature = () =>
  hasFeatures({ featureName: 'org_conversations_reports', accessLevel: 'READ' })

export const userHasConversationsReportAccess = () =>
  userHasAllReportAccess() ||
  isNonAdminChatUser() ||
  userHasMyConversationReportsFeature() ||
  userHasOrgConversationReportsFeature()

export const hasSunsetPlan = () => hasGates('sunset_plan_q2_2025')

export const hasCSATModal = () => hasGates('has_csat_modal')
