import React, { useEffect, useState } from 'react'

import { useMutation, useQuery } from '@tanstack/react-query'
import moment from 'moment'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'

import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  Modal,
  RadioGroup,
  RadioItem,
  Select,
  Text,
  Textarea,
} from '@driftt/dds'
import { tracking } from '@driftt/traffic-control'

import { fetchUserProperties, patchUserProperties } from 'api/User'

import { daysBetweenRange } from '../../utils/dateRangeUtils'

const SATISFACTION_SCORE_OPTIONS = [
  {
    label: '5 - Very satisfied',
    value: '5_VERY_SATISFIED',
  },
  {
    label: '4 - Somewhat satisfied',
    value: '4_SOMEWHAT_SATISFIED',
  },
  {
    label: '3 - Neutral',
    value: '3_NEUTRAL',
  },
  {
    label: '2 - Somewhat unsatisfied',
    value: '2_SOMEWHAT_UNSATISFIED',
  },
  {
    label: '1 - Very unsatisfied',
    value: '1_VERY_UNSATISFIED',
  },
]

const RATING_REASON_OPTIONS = [
  {
    label: 'Usability',
    value: 'USABILITY',
  },
  {
    label: 'Intuitiveness',
    value: 'INTUITIVENESS',
  },
  {
    label: 'Software performance',
    value: 'SOFTWARE_PERFORMANCE',
  },
  {
    label: 'Information provided',
    value: 'INFORMATION_PROVIDED',
  },
  {
    label: 'Data population or configuration',
    value: 'DATA_POPULATION_OR_CONFIGURATION',
  },
  {
    label: 'Visual appearance',
    value: 'VISUAL_APPEARANCE',
  },
  {
    label: 'Customer support or training',
    value: 'CUSTOMER_SUPPORT_OR_TRAINING',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },
]

const PRODUCT_AREA_OPTIONS = [
  { label: 'Playbooks', value: 'playbooks' },
  { label: 'Reports', value: 'reports' },
  { label: 'Prospector', value: 'prospector' },
  { label: 'Content library', value: 'content_library' },
  { label: 'Visitors', value: 'visitors' },
  { label: 'Settings', value: 'settings' },
] as const

type TrafficState = {
  user?: {
    createdAt: string
    email: string
    bot: boolean
    roles: string[]
    orgId: number
    id: number
  }
}

type CSATForm = {
  satisfactionScore: string
  rateReason: { label: string; value: string } | null
  productAreasUsed: Record<string, boolean>
  comments: string
}

export const CSATModal = () => {
  const [isOpen, setIsOpen] = useState(true)
  const user = useSelector((state: TrafficState) => {
    return state.user
  })
  const {
    createdAt,
    email,
    id: userId,
    bot: isBot,
    roles,
    orgId,
  } = user || ({} as NonNullable<TrafficState['user']>)

  const { data: userProperties, isLoading: isLoadingUserProperties } = useQuery(
    {
      queryKey: ['userProperties', userId],
      queryFn: () => fetchUserProperties(userId),
      select: (res) => res.data as Record<string, unknown>,
      enabled: !!userId,
    }
  )

  const {
    mutate: updateCSATSubmissionDate,
    isLoading: isUpdatingCSATSubmissionDate,
  } = useMutation({
    mutationKey: ['driftCSATSubmitted'],
    mutationFn: () => {
      return patchUserProperties(userId, {
        submittedDriftCSAT: new Date().getTime(),
      })
    },
  })

  const { submittedDriftCSAT } = userProperties ?? {}
  const driftCSATSubmitted = submittedDriftCSAT as number

  // CSAT was seen two months ago at least
  const canSubmitCSAT = isLoadingUserProperties
    ? false
    : !driftCSATSubmitted ||
      moment(driftCSATSubmitted).isBefore(moment().subtract(2, 'months'))

  const daysWithDrift = daysBetweenRange(createdAt)
  const excludedOrgs = [1, 13799]

  const { control, register, handleSubmit } = useForm<CSATForm>({
    defaultValues: {
      satisfactionScore: '',
      rateReason: null,
      productAreasUsed: {},
      comments: '',
    },
  })

  const handleClose = () => {
    updateCSATSubmissionDate()
    setIsOpen(false)
  }

  const onSubmit: SubmitHandler<CSATForm> = (v) => {
    const { satisfactionScore, rateReason, productAreasUsed, comments } = v
    tracking.trackEvent('csat-feedback', {
      email,
      roles: roles.join(','),
      orgId,
      satisfactionScore: SATISFACTION_SCORE_OPTIONS.find(
        (option) => option.value === satisfactionScore
      )?.label,
      rateReason: RATING_REASON_OPTIONS.find(
        (option) => option.value === rateReason?.value
      )?.label,
      productAreasUsed: Object.keys(productAreasUsed)
        .filter((key) => productAreasUsed[key])
        .join(','),
      comments,
    })
    updateCSATSubmissionDate()
    setIsOpen(false)
  }

  const hideCSAT =
    daysWithDrift < 180 ||
    isBot ||
    excludedOrgs.includes(orgId) ||
    !canSubmitCSAT

  useEffect(() => {
    if (!hideCSAT && window.drift) {
      setTimeout(() => {
        window.drift.hide()
      }, 8000)
    }
    return () => {
      if (!hideCSAT && window.drift) {
        window.drift.show()
      }
    }
  }, [hideCSAT])

  if (hideCSAT) {
    return null
  }

  return (
    <Modal.Root open={isOpen} onClose={handleClose}>
      <Modal.Dialog
        size="large"
        variant="info"
        css={{ width: '626px', maxWidth: '100%' }}
      >
        <Modal.Title
          title="We value your input!"
          subtitle="Please answer this quick survey"
        />
        <Modal.Content as={Flex} direction="column" gap={3} fullWidth>
          <Box>
            <Text>
              How satisfied are you with <b>Drift</b>?
            </Text>
            <Controller
              control={control}
              name="satisfactionScore"
              rules={{ required: 'Please select an option below.' }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <RadioGroup
                  label=""
                  value={value}
                  onValueChange={(v) => {
                    onChange(v)
                  }}
                  required
                  error={error?.message}
                >
                  {SATISFACTION_SCORE_OPTIONS.map((option) => (
                    <RadioItem
                      key={option.value}
                      label={option.label}
                      value={option.value}
                    />
                  ))}
                </RadioGroup>
              )}
            />
          </Box>
          <Box>
            <Flex gap="half" alignItems="center">
              <Text>What is the main reason for your rating?</Text>
              <Text variant="metadata">(optional)</Text>
            </Flex>
            <Controller
              control={control}
              name="rateReason"
              render={({ field: { onChange, value } }) => (
                <Select
                  error=""
                  label=""
                  options={RATING_REASON_OPTIONS}
                  placeholder="Select an one..."
                  size="large"
                  success=""
                  value={value}
                  onChange={(v) => onChange(v)}
                />
              )}
            />
          </Box>
          <Box>
            <Flex gap="half" alignItems="center">
              <Text>Which areas of the product do you use?</Text>
              <Text variant="metadata">(optional)</Text>
            </Flex>
            <Controller
              control={control}
              name="productAreasUsed"
              render={({ field: { onChange, value } }) => (
                <CheckboxGroup label="">
                  {PRODUCT_AREA_OPTIONS.map((area) => (
                    <Checkbox
                      key={area.label}
                      label={area.label}
                      checked={value[area.value]}
                      onChange={(v) => {
                        onChange({ ...value, [area.value]: v })
                      }}
                    />
                  ))}
                </CheckboxGroup>
              )}
            />
          </Box>
          <Box fullWidth>
            <Flex gap="half" alignItems="center">
              <Text>
                Please tell us more about why you gave this rating for{' '}
                <b>Drift.</b>
              </Text>
              <Text variant="metadata">(optional)</Text>
            </Flex>
            <Textarea
              label=""
              size="full-width"
              placeholder="Add comments here"
              {...register('comments')}
            />
          </Box>
        </Modal.Content>
        <Modal.Actions justifyContent="flex-end">
          <Button
            variant="secondary"
            disabled={isUpdatingCSATSubmissionDate}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={isUpdatingCSATSubmissionDate}
            onClick={handleSubmit(onSubmit)}
          >
            Submit
          </Button>
        </Modal.Actions>
      </Modal.Dialog>
    </Modal.Root>
  )
}
