import euConfig from './eu.json'
import prodConfig from './production.json'
import qaConfig from './qa.json'

declare global {
  interface Window {
    DRIFT_CONFIG: Record<string, string | number | boolean>
    DRIFT_REGION: string
    __ENV__: string
    drift: {
      show: () => void
      hide: () => void
    }
  }
}

type LocalConfigs = {
  [key: string]: typeof qaConfig | typeof euConfig | typeof prodConfig
}

const localConfigs: LocalConfigs = {
  QA: qaConfig,
  EU: euConfig,
  US: prodConfig,
}

export default {
  ...(window.DRIFT_CONFIG || {}),
  ...localConfigs[
    window.DRIFT_REGION || (window.__ENV__ === 'PRODUCTION' ? 'US' : 'QA')
  ],
}
