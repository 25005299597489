import { capitalize } from 'humanize-plus'
import moment from 'moment'
import { isEmpty, isNil } from 'ramda'

export const isNilOrEmpty = (value) => isNil(value) || isEmpty(value)

export const TWO_CALENDAR_YEARS_BACK = moment()
  .startOf('year')
  .subtract(2, 'years')

class TypeFormats {
  static DATE = (value) => moment(value).format('YYYY-MM-DD')
  static DATE_SLASH = (value) => moment(value).format('MM/DD/YYYY')
  static CAPITALIZE = (value) => capitalize(value)
  static TIME = (value, timezone) =>
    moment(value).tz(timezone).format('hh:mm A z')
}

export const calculateTimePeriod = (start, end) => {
  const startDate = moment(TypeFormats.DATE(start))
  const endDate = moment(TypeFormats.DATE(end))
  const diff = moment(endDate).diff(startDate, 'days')
  return {
    startDate,
    endDate,
    prevStart: moment(startDate).subtract(1 + diff, 'days'),
    prevEnd: moment(startDate).subtract(1, 'day'),
  }
}

export const calculateLastThirtyDaysTimePeriod = () =>
  calculateTimePeriod(moment().subtract(30, 'days'), moment())

export const DEFAULT_TIME_PERIOD = calculateLastThirtyDaysTimePeriod()

export const parseDateToRange = (startDate, endDate) => {
  if (startDate.isSame(endDate)) {
    return startDate.format('MMMM D, YYYY')
  }

  const startDateFormat = `MMMM D${
    startDate.isSame(endDate, 'year') ? '' : ', YYYY'
  }`
  const endDateFormat = startDate.isSame(endDate, 'month')
    ? 'D, YYYY'
    : 'MMMM D, YYYY'
  return `${startDate.format(startDateFormat)} – ${endDate.format(
    endDateFormat
  )}`
}

export const mapDatesToSeriesLabels = (timePeriod) => {
  return {
    series: parseDateToRange(timePeriod.startDate, timePeriod.endDate),
    previousSeries: parseDateToRange(timePeriod.prevStart, timePeriod.prevEnd),
  }
}

export const formatDate = (date, format = 'MM/DD/YYYY') =>
  date ? moment(date).format(format) : 'Date not found'

const compareSameTimePeriods = (timePeriodA, timePeriodB) => (key) =>
  timePeriodA !== null &&
  timePeriodB !== null &&
  timePeriodA[key].isSame(timePeriodB[key], 'day')

export const areSameTimePeriods = (timePeriodA, timePeriodB) => {
  const isSame = compareSameTimePeriods(timePeriodA, timePeriodB)
  return (
    isSame('startDate') &&
    isSame('endDate') &&
    isSame('prevStart') &&
    isSame('prevEnd')
  )
}
export const daysBetweenRange = (start, end) => {
  const startDate = moment(start)
  const endDate = end ? moment(end) : moment()

  // Calculate difference in days
  return Math.abs(endDate.diff(startDate, 'days'))
}
